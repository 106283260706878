// @flow

import React from 'react'
import classNames from 'classnames'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import Button from '../../../components/basic/Button'
import Carousel from '../../../components/basic/Carousel'
import BackgroundOverlay from '../../CommonLanding/shared/BackgroundOverlay'
import baseStyles from './RelatedCS.scss'
import { projectsList } from '../../CaseStudy/helpers'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

import messages from '../CaseStudyModern.lang'

type Props = {
  title?: any,
  items?: Array<string>,
  modeSettings?: Object,
  id?: string,
  ...StyleProps
}

const RelatedCS = ({ id, modeSettings, title, items, styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div
      id={id}
      className={classNames(styles.root, modeSettings && modeSettings.mode === 'dark' && styles.isDark)}
    >
      <BackgroundOverlay styles={styles.overlay} {...modeSettings} />
      <Container className={styles.container} fluid>
        <h2
          dangerouslySetInnerHTML={{ __html: formatMessage(title || messages.casesTitle) }}
          className={styles.title}
        />
        <div className={styles.sliderWrap}>
          <Carousel
            styles={styles.slider}
            settings={{
              swipe: true,
              lazyLoad: false,
              infinite: false,
              dots: false,
              adaptiveHeight: true,
              responsive: [
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 1199,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 1439,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    swipe: true
                  }
                },
                {
                  breakpoint: 9999,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    swipe: true
                  }
                }
              ]
            }}
          >
            {items &&
              items.map((item, i) => (
                <div className={styles.itemWrap} key={'casesItem' + i}>
                  <div className={styles.item}>
                    <div className={styles.imageWrap}>
                      <div className={styles.imageContainer}>{projectsList[item].marketingImage}</div>
                    </div>
                    <div className={styles.panel}>
                      <div className={styles.preTitle}>{formatMessage(projectsList[item].name)}</div>
                      <h2 className={styles.itemTitle}>
                        <FormattedMessageSanitized {...projectsList[item].title} />
                      </h2>
                      <p className={styles.description}>{formatMessage(projectsList[item].description)}</p>
                      <Link to={projectsList[item].localLink} className={styles.btnLink}>
                        <Button styles={styles.btn} tag="span" color="link">
                          {formatMessage(messages.casesAction)}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </Container>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(RelatedCS)
