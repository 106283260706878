import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  backBtnLabel: 'Back',
  techTitle: 'Tech & Services',
  theCompany: 'The Company',
  theChallenge: 'The Challenge',
  ourProducts: 'Our products',
  casesTitle: 'Selected success stories',
  casesAction: 'Check it Out',
  contactText: 'Do you have any questions? Please do not hesitate to contact us.',
  contactBtn: 'To the contact form'
})
